<template>
  <v-form>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Company Name"
          v-model="record.CompnyName"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Street P.O Box"
          v-model="record.BTWStreet"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Block"
          v-model="record.DRBlock1"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Building/Floor/Room"
          v-model="record.DRBlock2"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="City"
          v-model="record.BTWCity"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Zip Code"
          v-model="record.BTWZip"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="County"
          v-model="record.HQLocation"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Telephone"
          v-model="record.Phone1"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="Email"
          v-model="record.E_Mail"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          outlined
          dense
          label="Address"
          v-model="record.CompnyAddr"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-btn
          v-if="Object.keys(record).length === 0"
          color="accent"
          @click="sendData"
        >
          <v-icon left>mdi-content-save</v-icon> Update
        </v-btn>

        <v-btn v-else color="accent" @click="sendData">
          <v-icon left></v-icon>Edit
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
    date: new Date().toISOString().substr(0, 10),
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    sendData() {
      this.$emit("data", this.record);
    },
  },
};
</script>