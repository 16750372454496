<template>
  <div>
    <editor :initial="dataItem" @data="save"></editor>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Editor from "../_components/general-editor";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
  }),

  methods: {
    getCompanyDetails() {
      const self = this;
      this.$store
        .dispatch("get", `/company_details`)
        .then((res) => {
          console.log(res);
          self.dataItem = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      console.log(data);
      const url = `/company_detail`;
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
  },
  created() {
    this.getCompanyDetails();
  },
};
</script>